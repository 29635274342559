import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Autumn Term 2023",
  "date": "2023-08-28",
  "author": "Andy B",
  "featuredImage": "path.jpg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi everyone, just a quick post to let you know the dates for the upcoming term:`}</p>
    <ul>
      <li parentName="ul">{`9th Sept`}</li>
      <li parentName="ul">{`23rd Sept`}</li>
      <li parentName="ul">{`7th Oct`}</li>
      <li parentName="ul">{`21st Oct`}</li>
      <li parentName="ul">{`4th Nov`}</li>
      <li parentName="ul">{`18th Nov`}</li>
      <li parentName="ul">{`2nd Dec`}</li>
      <li parentName="ul">{`16th Dec`}</li>
    </ul>
    <p>{`Spaces for the sessions in September and October can be booked now, in the `}<a parentName="p" {...{
        "href": "https://zen.coderdojo.com/dojos/gb/cheltenham/bishops-cleeve-bishops-cleeve-library"
      }}>{`usual place`}</a>{`.`}</p>
    <p>{`We look forward to welcoming you back to the library in a few weeks, and seeing what amazing things you'll build this year!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      